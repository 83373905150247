import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    generateMemorialBookActions,
    validateMemorialBookGenerationActions,
} from '../memorial-book.actions';

import { generateMemorialBookSaga } from './generate-memorial-book.saga';
import { validateMemorialBookGenerationSaga } from './validate-memorial-book-generation.saga';

export function* memorialBookSaga() {
    yield takeEvery(
        getType(generateMemorialBookActions.request),
        generateMemorialBookSaga,
    );

    yield takeEvery(
        getType(validateMemorialBookGenerationActions.request),
        validateMemorialBookGenerationSaga,
    );
}
