import { call, put, select } from 'redux-saga/effects';

import { fetchBackgroundPhotosApiActions } from '../background-photos.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { IBackgroundImageDto } from '../dto/background-image.dto';
import { fetchBackgroundImages } from '../http/fetch-background-images';

export function* fetchBackgroundPhotosSaga(
    action: ReturnType<typeof fetchBackgroundPhotosApiActions.request>,
) {
    const slug: string = yield select(getPageSlug);

    try {
        const resp: IBackgroundImageDto[] = yield call(
            fetchBackgroundImages,
            slug,
        );
        yield put(
            fetchBackgroundPhotosApiActions.success(
                resp.map((bgImage) => ({
                    src: bgImage.attributes.backgroundUrl,
                    id: bgImage.id,
                })),
            ),
        );
    } catch (err) {
        yield put(fetchBackgroundPhotosApiActions.failure(err));
    }
}
