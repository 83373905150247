import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../components/page-title/page-title';
import { MemorialBook } from '../../memorial-book/memorial-book';

export const MemorialBookScreen = () => {
    const { t } = useTranslation();

    return (
        <Box data-testid="memorial-book-page-container">
            <PageTitle>{t('memorialBook.title')}</PageTitle>
            <MemorialBook />
        </Box>
    );
};
