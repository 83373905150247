import { IIncluded } from '../../../utils/included';

export enum MemorialBookStatus {
    INITIAL = 'initial',
    STARTED = 'started',
    APPROVED = 'approved',
    WAITING_FOR_APPROVAL = 'waiting_for_approval',
}

export interface IMemorialBook {
    createdAt: string | null;
    updatedAt: string | null;
    im4ProjectId: number | null;
    startedByProfileId: number | null;
    status: MemorialBookStatus | null;
    im4PdfUrl?: string | null;
    bookDrafts?: Array<{ profileId: string | null; im4PdfUrl: string | null }>;
}

export type IMemorialBookDto = IIncluded<'memorial_book', IMemorialBook>;
