import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from '@redux-saga/core';
import { StateType } from 'typesafe-actions';

import { browserHistory } from '../routing/browser-history';
import { wizardStore } from '../wizard/wizard.store';
import { editorStore } from '../editor/editor.store';
import { metaReducer } from '../meta/model/meta.store';
import { authStore } from '../auth/auth.store';
import { deceasedPersonModelReducer } from '../model/deceased-person/deceased-person.store';
import { deceasedDisplayImagesReducer } from '../model/deceased-display-images/deceased-display-images.store';
import { tributeModelReducer } from '../model/tribute/tribute.store';
import { obituaryModelReducer } from '../model/obituary/obituary.store';
import { notificationsMessageReducer } from '../model/notification-message/notification-message.store';
import { notificationContactsReducer } from '../model/notification-contacts/notification-contacts.store';
import { configurationReducer } from '../model/configuration/configuration.store';
import { condolencesReducer } from '../model/condolences/condolences.store';
import { invitationsReducer } from '../model/invitations/invitations.store';
import { suggestionsReducer } from '../model/suggestions/suggestions.store';
import { gallerySuggestionsReducer } from '../model/gallery-suggestions/gallery-suggestions.store';
import { tasksReducer } from '../model/tasks/tasks.store';
import { tasksSectionReducer } from '../tasks/model/tasks.store';
import { milestonesReducer } from '../model/milestones/milestones.store';
import { milestonesSectionReducer } from '../milestones/model/milestones-section.store';
import { galleryReducer } from '../model/gallery/gallery.store';
import { gallerySectionReducer } from '../gallery/model/gallery-section.store';
import { overviewReducer } from '../model/overview/overview.store';
import { themeReducer } from '../model/theme/theme.store';
import { vgReducer } from '../model/vg/vg.store';
import { participantsReducer } from '../model/participants/participants.store';
import { memorialBookReducer } from '../memorial-book/model/memorial-book.store';

import { runSagas } from './sagas';

export const sagaMiddleware = createSagaMiddleware();

export const createRootReducers = () =>
    combineReducers({
        router: connectRouter(browserHistory),
        wizard: wizardStore,
        editor: editorStore,
        auth: authStore,
        meta: metaReducer,
        deceasedPerson: deceasedPersonModelReducer,
        deceasedDisplayImages: deceasedDisplayImagesReducer,
        tributeModel: tributeModelReducer,
        obituaryModel: obituaryModelReducer,
        notificationMessage: notificationsMessageReducer,
        notificationContacts: notificationContactsReducer,
        configuration: configurationReducer,
        condolences: condolencesReducer,
        invitations: invitationsReducer,
        suggestions: suggestionsReducer,
        tasks: tasksReducer,
        tasksSection: tasksSectionReducer,
        milestones: milestonesReducer,
        milestonesSection: milestonesSectionReducer,
        gallery: galleryReducer,
        gallerySection: gallerySectionReducer,
        gallerySuggestions: gallerySuggestionsReducer,
        overview: overviewReducer,
        theme: themeReducer,
        vg: vgReducer,
        participants: participantsReducer,
        memorialBook: memorialBookReducer,
    });

const rootStore = createRootReducers();

export type RootStore = StateType<typeof rootStore>;

const initReduxStore = () =>
    createStore(
        rootStore,
        composeWithDevTools(
            applyMiddleware(sagaMiddleware, routerMiddleware(browserHistory)),
        ),
    );

export const store = initReduxStore();

runSagas(sagaMiddleware);
