import { spawn } from 'redux-saga/effects';

import { contextSaga } from '../auth/contexts/model/saga/context.saga';

import { contactsApiSaga } from './contacts/saga/contacts-api.saga';
import { settingsSaga } from './settings/saga/settings.saga';
import { loginApiSaga } from './login/saga/login-api.saga';
import { pageSaga } from './page/saga/page.saga';
import { invitationSaga } from './invitation-check/saga/invitation-check.saga';
import { dictionariesApiSaga } from './dictionaries/saga/dictionaries-api.saga';
import { apiPasswordSaga } from './password/saga/api-password.saga';
import { apiEventsSaga } from './events/saga/api-events.saga';
import { deceasedApiSaga } from './deceased/saga/deceased-api.saga';
import { tasksApiSaga } from './tasks/saga/tasks-api.saga';
import { milestonesApiSaga } from './milestones/saga/milestones-api.saga';
import { galleryApiSaga } from './gallery/saga/gallery-api.saga';
import { backgroundPhotosSaga } from './background-photos/saga/background-photos.saga';
import { memorialCreationStepApiSaga } from './memorial-creation-step/saga/memorial-creation-step-api.saga';
import { condolencesApiSaga } from './condolences/saga/condolences-api.saga';
import { invitationsApiSaga } from './invitations/saga/invitations-api.saga';
import { suggestionsApiSaga } from './suggestions/saga/suggestions-api.saga';
import { tributeApiSaga } from './tribute/saga/tribute-api.saga';
import { obituaryApiSaga } from './obituary/saga/obituary-api.saga';
import { gallerySuggestionsApiSaga } from './gallery-suggestions/saga/gallery-suggestions-api.saga';
import { overviewApiSaga } from './overview/saga/overview-api.saga';
import { vgApiSaga } from './vg/saga/vg-api.saga';
import { participantsApiSaga } from './participants/saga/participants-api.saga';
import { memorialBookApiSaga } from './memorial-book/saga/memorial-book-api.saga';

/**
 * Spawn sagas related to operations on API
 */
export function* apiSaga() {
    yield spawn(contactsApiSaga);
    yield spawn(settingsSaga);
    yield spawn(loginApiSaga);
    yield spawn(pageSaga);
    yield spawn(invitationSaga);
    yield spawn(dictionariesApiSaga);
    yield spawn(apiPasswordSaga);
    yield spawn(apiEventsSaga);
    yield spawn(deceasedApiSaga);
    yield spawn(tasksApiSaga);
    yield spawn(milestonesApiSaga);
    yield spawn(galleryApiSaga);
    yield spawn(backgroundPhotosSaga);
    yield spawn(memorialCreationStepApiSaga);
    yield spawn(condolencesApiSaga);
    yield spawn(invitationsApiSaga);
    yield spawn(suggestionsApiSaga);
    yield spawn(tributeApiSaga);
    yield spawn(obituaryApiSaga);
    yield spawn(gallerySuggestionsApiSaga);
    yield spawn(overviewApiSaga);
    yield spawn(contextSaga);
    yield spawn(vgApiSaga);
    yield spawn(participantsApiSaga);
    yield spawn(memorialBookApiSaga);
}
