import { call, put, select } from 'redux-saga/effects';

import { validateMemorialBookGeneration } from '../http/validate-memorial-book-generation';
import { validateMemorialBookGenerationApiActions } from '../memorial-book.api.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';

export function* validateMemorialBookApiSaga(
    action: ReturnType<typeof validateMemorialBookGenerationApiActions.request>,
) {
    const slug: string = yield select(getPageSlug);

    try {
        const response: boolean = yield call(
            validateMemorialBookGeneration,
            slug,
        );
        yield put(validateMemorialBookGenerationApiActions.success(response));
        yield put(validateMemorialBookGenerationApiActions.success(true));
    } catch (e) {
        yield put(
            validateMemorialBookGenerationApiActions.failure(e.statusText),
        );
    }
}
