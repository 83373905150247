import { apiService } from '../../api.service';
import { IGenerateMemorialResponseDto } from '../dto/generate-memorial-book.dto';

export const generateMemorialBook = (
    slug: string,
): Promise<IGenerateMemorialResponseDto> =>
    apiService
        .post(`/memorial_pages/${slug}/memorial_books`)
        .then((resp) => resp.json())
        .then((resp) => resp.data);
