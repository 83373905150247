import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MemorialBookStatus } from '../api/memorial-book/dto/memorial-book-response.dto';
import { Section } from '../editor/components/section/section';
import { getDeceasedData } from '../model/deceased-person/selectors/get-deceased-data.selector';

import { useMemorialBookState } from './use-memorial-book-state';
import { GenerateMemorialBook } from './generate-memorial-book';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(5, 10),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
        },
    },
}));

export const MemorialBook = () => {
    const { t } = useTranslation();
    const styles = useStyles();
    const {
        memorialBookData,
        bookGenerationRequestSend,
    } = useMemorialBookState();
    const deceasedPersonData = useSelector(getDeceasedData);

    const renderMemorialBookStateContent = useMemo(() => {
        if (
            memorialBookData &&
            memorialBookData.status === MemorialBookStatus.STARTED
        ) {
            return <></>;
        }
        if (
            memorialBookData &&
            memorialBookData.status === MemorialBookStatus.APPROVED
        ) {
            return <div>approved</div>;
        }
        return <GenerateMemorialBook />;
    }, [memorialBookData]);

    const sectionTitle = useMemo(() => {
        if (
            memorialBookData &&
            memorialBookData.status === MemorialBookStatus.STARTED
        ) {
            return t('memorialBook.startedTitle');
        }
        if (
            memorialBookData &&
            memorialBookData.status === MemorialBookStatus.APPROVED
        ) {
            return t('memorialBook.approvedTitle');
        }
        return t('memorialBook.generateTitle');
    }, [memorialBookData, t]);

    const sectionDescription = useMemo(() => {
        if (
            memorialBookData &&
            memorialBookData.status === MemorialBookStatus.STARTED
        ) {
            return t('memorialBook.startedDescription', {
                deceasedName: deceasedPersonData.name,
            });
        }
        if (
            memorialBookData &&
            memorialBookData.status === MemorialBookStatus.APPROVED
        ) {
            return t('memorialBook.approvedDescription');
        }
        if (bookGenerationRequestSend) {
            return '';
        }
        return t('memorialBook.generateDescription');
    }, [
        bookGenerationRequestSend,
        deceasedPersonData.name,
        memorialBookData,
        t,
    ]);

    return (
        <Section
            title={sectionTitle}
            description={sectionDescription}
            className={styles.paper}
        >
            {renderMemorialBookStateContent}
        </Section>
    );
};
