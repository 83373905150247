import React from 'react';
import { RouteProps } from 'react-router';

import { ComposedWizardLayout } from '../wizard/wizard-layout/wizard-layout';
import { ComposedEditorLayout } from '../editor/editor-layout/editor-layout';
import { ComposedLoginPage } from '../auth/login-page/login-page';
import { ComposedPasswordPage } from '../auth/password-page/password-page';
import { ResetPasswordPage } from '../auth/reset-password-page/reset-password-page';
import { ErrorPage } from '../error-page/error-page';
import { ContextSelection } from '../auth/contexts/context-selection';
import { LinkExpiredPage } from '../auth/link-expired-page/link-expired';

import { WizardPath } from './wizard-paths';
import { EditorPath } from './editor-paths';
import { AuthPath } from './auth-path';

export const routes: RouteProps[] = [
    {
        path: [
            WizardPath.NOTIFICATION,
            WizardPath.PHOTOS,
            WizardPath.DECEASED,
            WizardPath.VG,
        ],
        component: ComposedWizardLayout,
    },
    {
        path: [
            EditorPath.OVERVIEW,
            EditorPath.DECEASED,
            EditorPath.MEDIA_AND_CONTENT,
            EditorPath.OBITUARY,
            EditorPath.NOTIFICATIONS,
            EditorPath.CEREMONY_AND_RECEPTION,
            EditorPath.PARTICIPANTS,
            EditorPath.DIGITAL_FOOTPRINT,
            EditorPath.SETTINGS,
            EditorPath.BOOK,
        ],
        component: ComposedEditorLayout,
    },
    {
        path: [
            AuthPath.LOGIN,
            AuthPath.LOGIN_WITHOUT_SLUG,
            AuthPath.FORGOT_PASSWORD,
        ],
        component: ComposedLoginPage,
    },
    {
        path: AuthPath.SET_PASSWORD,
        component: ComposedPasswordPage,
    },
    {
        path: [AuthPath.RESET_PASSWORD, AuthPath.RESET_PASSWORD_WITHOUT_SLUG],
        component: ResetPasswordPage,
    },
    {
        path: AuthPath.CONTEXTS,
        component: ContextSelection,
    },
    {
        path: AuthPath.LINK_EXPIRED,
        component: LinkExpiredPage,
    },
    {
        path: ['/not-found'],
        render: (props) => <ErrorPage status={404} {...props} />,
    },
];
