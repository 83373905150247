import { createReducer } from 'typesafe-actions';

import { memorialBookDataReceived } from '../../api/memorial-book/memorial-book.api.actions';
import { IMemorialBook } from '../../api/memorial-book/dto/memorial-book-response.dto';

import {
    generateMemorialBookActions,
    generateMemorialBookDataReceived,
    validateMemorialBookGenerationActions,
} from './memorial-book.actions';

export interface IMemorialBookStore {
    bookGenerationInProgress: boolean;
    bookGenerationPossible: boolean;
    generateMemorialBookRequestSend: boolean;
    generationError: string | null;
    memorialBook: IMemorialBook | null;
}

const initialState: IMemorialBookStore = {
    bookGenerationInProgress: false,
    bookGenerationPossible: false,
    generateMemorialBookRequestSend: false,
    generationError: null,
    memorialBook: null,
};

type Actions =
    | ReturnType<typeof validateMemorialBookGenerationActions.request>
    | ReturnType<typeof validateMemorialBookGenerationActions.success>
    | ReturnType<typeof validateMemorialBookGenerationActions.failure>
    | ReturnType<typeof generateMemorialBookActions.request>
    | ReturnType<typeof generateMemorialBookActions.success>
    | ReturnType<typeof generateMemorialBookActions.failure>
    | ReturnType<typeof memorialBookDataReceived>
    | ReturnType<typeof generateMemorialBookDataReceived>;

export const memorialBookReducer = createReducer<IMemorialBookStore, Actions>(
    initialState,
)
    .handleAction(generateMemorialBookActions.request, (state) => ({
        ...state,
        bookGenerationInProgress: true,
        generationError: null,
    }))
    .handleAction(generateMemorialBookActions.failure, (state, action) => ({
        ...state,
        bookGenerationInProgress: false,
        generationError: action.payload,
    }))
    .handleAction(generateMemorialBookActions.success, (state) => ({
        ...state,
        bookGenerationInProgress: false,
        generateMemorialBookRequestSend: true,
    }))
    .handleAction(validateMemorialBookGenerationActions.success, (state) => ({
        ...state,
        bookGenerationPossible: true,
    }))
    .handleAction(memorialBookDataReceived, (state, action) => ({
        ...state,
        memorialBook: action.payload,
    }))
    .handleAction(generateMemorialBookDataReceived, (state, action) => ({
        ...state,
        memorialBook: {
            ...state.memorialBook,
            ...action.payload,
        },
    }));
