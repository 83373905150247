export enum EditorPath {
    OVERVIEW = '/:slug/editor/overview',
    DECEASED = '/:slug/editor/deceased',
    MEDIA_AND_CONTENT = '/:slug/editor/media-and-content',
    OBITUARY = '/:slug/editor/obituary',
    NOTIFICATIONS = '/:slug/editor/notifications',
    CEREMONY_AND_RECEPTION = '/:slug/editor/ceremony-and-reception',
    DIGITAL_FOOTPRINT = '/:slug/editor/digital-footprint',
    SETTINGS = '/:slug/editor/settings',
    ROOT = '/:slug/editor',
    PARTICIPANTS = '/:slug/participants',
    BOOK = '/:slug/editor/book',
}
