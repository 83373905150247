import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import {
    getIsBookGenerationInProgress,
    getIsBookGenerationPossible,
    getIsMemorialBookGenerationRequestSend,
    getMemorialBookData,
    getMemorialBookGenerationErrorStatus,
} from './model/selectors/memorial-book.selector';
import {
    generateMemorialBookActions,
    // validateMemorialBookGenerationActions,
} from './model/memorial-book.actions';

export const useMemorialBookState = () => {
    const dispatch = useDispatch();
    const bookGenerationPossible = useSelector(getIsBookGenerationPossible);
    const bookGenerationRequestInProgress = useSelector(
        getIsBookGenerationInProgress,
    );
    const bookGenerationRequestSend = useSelector(
        getIsMemorialBookGenerationRequestSend,
    );
    const bookGenerationErrorStatus = useSelector(
        getMemorialBookGenerationErrorStatus,
    );
    const memorialBookData = useSelector(getMemorialBookData);

    // useEffect(() => {
    //     dispatch(validateMemorialBookGenerationActions.request());
    // }, [dispatch]);

    const generateMemorialBook = useCallback(() => {
        dispatch(generateMemorialBookActions.request());
    }, [dispatch]);

    return {
        bookGenerationPossible,
        bookGenerationRequestInProgress,
        bookGenerationRequestSend,
        bookGenerationErrorStatus,
        memorialBookData,
        generateMemorialBook,
    };
};
