import { put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { validateMemorialBookGenerationApiActions } from '../../../api/memorial-book/memorial-book.api.actions';
import { validateMemorialBookGenerationActions } from '../memorial-book.actions';

type PossibleApiResults =
    | ReturnType<typeof validateMemorialBookGenerationApiActions.success>
    | ReturnType<typeof validateMemorialBookGenerationApiActions.failure>;

export function* validateMemorialBookGenerationSaga(
    action: ReturnType<typeof validateMemorialBookGenerationActions.request>,
) {
    yield put(validateMemorialBookGenerationApiActions.request());

    const result: PossibleApiResults = yield take([
        validateMemorialBookGenerationApiActions.success,
        validateMemorialBookGenerationApiActions.failure,
    ]);

    switch (result.type) {
        case getType(validateMemorialBookGenerationApiActions.success):
            yield put(
                validateMemorialBookGenerationActions.success(result.payload),
            );
            break;
        case getType(validateMemorialBookGenerationApiActions.failure):
            yield put(
                validateMemorialBookGenerationActions.failure(result.payload),
            );
            break;
        default:
            yield;
    }
}
